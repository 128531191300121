<template>
  <div class="container">
    <van-tabs class="tabs-box" v-model="active" @change="onChange">
      <van-tab :title="'待澄清(' + opend + ')'"></van-tab>
      <van-tab :title="'已澄清(' + replid + ')'"></van-tab>
      <van-tab :title="'已关闭(' + confirmd + ')'"></van-tab>
    </van-tabs>
    <div class="scroll-box">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list v-model="loading" :finished="finished" @load="onLoad">
          <div
            class="rh-list"
            v-for="item in list"
            @click="onQueryClick(item)"
            :key="item.id"
          >
            <div class="info-one">
              <span class="label-name">#</span>
              <span class="value-query">{{ item.query_num }}</span>
            </div>
            <div class="info-one">
              <span class="label-name">受试者编号</span>
              <span class="value-query">{{ item.subjid }}</span>
            </div>
            <div class="info-one">
              <span class="label-name">中心</span>
              <span class="value-query"
                >({{ item.study_site_num }}){{ item.site_name }}</span
              >
            </div>
            <div class="info-one">
              <span class="label-name">访视</span>
              <span class="value-query">{{ item.folder_name }}</span>
            </div>
            <div class="info-one">
              <span class="label-name">表单</span>
              <span class="value-query">{{ item.form_name }}</span>
            </div>
            <div class="info-one">
              <span class="label-name">字段标签</span>
              <span class="value-query">{{ item.field_label }}</span>
            </div>
            <div class="info-one">
              <span class="label-name">质疑文本</span>
              <span class="value-query">{{ item.add_reason }}</span>
            </div>
            <div class="info-one" v-if="active == 0">
              <span class="label-name">质疑用户</span>
              <span class="value-query"
                >{{ item.from_name }}({{ item.permission_role }})</span
              >
            </div>
            <div class="info-one" v-if="active == 1">
              <span class="label-name">澄清人</span>
              <span class="value-query">{{ item.reply_name }}</span>
            </div>
            <div class="info-one" v-if="active == 2">
              <span class="label-name">关闭人</span>
              <span class="value-query">{{ item.confirm_name }}</span>
            </div>
          </div>
        </van-list>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
import { List, Tabs, Tab, PullRefresh, Toast } from "vant";
import service from "../js/service";
import qs from "qs";

export default {
  name: "query-list",
  components: {
    [List.name]: List,
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
    [PullRefresh.name]: PullRefresh,
  },
  data() {
    return {
      active: 0,
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
      params: {},
      opend: 0,
      replid: 0,
      confirmd: 0,
      queryClose: 0,
      queryAdd: 0,
      queryReply: 0,
      roleId: "",
      envName: "",
      toast: null,
    };
  },
  activated() {
    const {
      envId,
      projectId,
      form_oid,
      field_oid,
      subjectId,
      queryClose,
      queryAdd,
      queryReply,
      roleId,
      envName,
      subject_item_id,
      active,
    } = this.$route.query;
    this.active = active ? Number(active) : 0;
    // 请求参数
    this.params = {
      page_num: 10,
      page: 0,
      query_num: "", // 关键字
      form_oid: "", // 表单值
      field_oid: "", // 字段值
      permission_role: "", // 角色值
      create_user_id: "", // 质疑用户值
      created_by: "", // 创建人的值
      project_id: "",
      env_id: "",
      subjectId: subjectId,
      status: this.active,
      subject_item_id: subject_item_id,
    };
    this.params.project_id = projectId;
    this.params.env_id = envId;
    this.params.form_oid = form_oid;
    this.params.field_oid = field_oid;

    this.queryClose = queryClose || 0;
    this.queryAdd = queryAdd || 0;
    this.queryReply = queryReply || 0;
    this.roleId = roleId || "";
    this.envName = envName || "";
    this.list = [];
    this.refreshing = false;
    this.loading = true;
    this.onLoad();
    this.toast = Toast.loading("加载中...");
  },
  methods: {
    onLoad() {
      this.loading = true;
      this.params.page++;
      this.getTableData();
    },
    onRefresh() {
      // 清空列表数据
      this.list = [];
      this.params.page = 0;

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.onLoad();
    },
    async getTableData() {
      let str = qs.stringify(this.params);
      const res = await service.sendReq(
        "get-subject-query?" + str,
        null,
        "get"
      );
      if (res.data.code === 200) {
        const data = res.data.data;
        const arrNum = res.data.arrNum;
        this.opend = arrNum.opend;
        this.replid = arrNum.replid;
        this.confirmd = arrNum.confirmd;
        let active = this.active;
        let total =
          active === 0
            ? arrNum.opend
            : active === 1
            ? arrNum.replid
            : arrNum.confirmd;
        if (Array.isArray(data) && data.length) {
          this.list.push(...data);
        }
        this.finished = this.list.length >= total;
        this.refreshing = false;
        this.loading = false;
        this.toast.clear();
      }
    },
    onChange() {
      this.list = [];
      this.params.page = 0;
      this.params.status = this.active;
      this.finished = false;
      this.$router.replace({
        path: "/queryList",
        query: {
          ...this.$route.query,
          active: this.active,
        },
      });
    },
    onQueryClick(item) {
      this.$router.push({
        path: "/queryDetail",
        query: {
          envId: this.params.env_id,
          projectId: this.params.project_id,
          roleId: this.roleId,
          envName: this.envName,
          id: item.id,
          queryClose: this.queryClose,
          queryAdd: this.queryAdd,
          queryReply: this.queryReply,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../style/index.scss";
.container {
  width: 100%;
  min-height: 100vh;
  background-color: $bg-grey;
}
.rh-list {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 5px 5px $bg-grey;
  padding: 16px;
  box-sizing: border-box;
  margin: 10px 0 0;
}
.rh-list {
  display: block;
  padding: 10px 16px;
}
.info-one {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 5px;
}

.label-name {
  width: 100px;
  display: block;
  color: #646566;
  text-align: left;
  word-wrap: break-word;
  font-size: 14px;
}
.value-query {
  flex: 1;
  display: block;
  text-align: left;
}
.tabs-box {
  position: sticky;
  top: 0;
  z-index: 10;
}
.scroll-box {
  min-height: calc(100vh - 55px);
  width: 96%;
  padding-bottom: 10px;
  margin: auto;
  ::v-deep .van-pull-refresh {
    height: 100%;
  }
}
</style>